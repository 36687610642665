@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
} 

code {
  font-family: source-code-pro, "Courier New",
    monospace;
}
